import {Flex, Icon, Text, useTheme} from "@aws-amplify/ui-react";
import styled from 'styled-components';
import {useNavigate} from "react-router-dom";

const GepsilonText = styled(Text)`
  text-wrap: nowrap;
  flex-wrap: nowrap;
  color: var(--amplify-colors-neutral-10);
  font-family: Inter;
  font-size: 1.25rem;
  cursor: pointer;
  margin-top: 4px;
`;

const GepsilonG = styled(Text)`
  color: var(--amplify-colors-neutral-10);
  font-family: 'Inter';
  font-size: 1.5rem;
  cursor: pointer;
`;

export function GepsilonLogo() {
    const {tokens} = useTheme();
    const navigate = useNavigate();

    return (
        <Flex as="button" onClick={() => navigate('/')} marginInlineStart="15px" whiteSpace="nowrap" direction="row" gap="0" wrap="nowrap" ariaLabel="Gepsilon Logo">
            <GepsilonG fontWeight="600">G</GepsilonG>
            <GepsilonText as='span' >epsilon Services</GepsilonText>
        </Flex>
    );
}