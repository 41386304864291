import React from 'react';
import ReactDOM from 'react-dom/client';
import '@aws-amplify/ui-react/styles.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import { ThemeProvider, defaultDarkModeOverride, Theme } from '@aws-amplify/ui-react';
import {GepsilonTheme} from "./GepsilonTheme";

Amplify.configure({
    aws_cognito_region: 'us-east-1', // (required) - Region where Amazon Cognito project was created
    aws_user_pools_id: 'us-east-1_8lkV9q4zi', // (optional) -  Amazon Cognito User Pool ID
    aws_user_pools_web_client_id: '9k45d8v1kjva11p1vnit22ofk', // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
    aws_mandatory_sign_in: 'enable'
});



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <ThemeProvider theme={GepsilonTheme}><App /></ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: ht