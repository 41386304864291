import {Button, Icon, useAuthenticator} from "@aws-amplify/ui-react";
import {MdLogin, MdLogout} from "react-icons/md";
import React from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";


const AuthButton = styled(Button)`
  border: none;
  background-color: transparent;
  color: var(--amplify-colors-neutral-40);
  
  &:hover {
    color: var(--amplify-colors-neutral-20);
    border: none;
    background-color: transparent;
  }
  
  &:focus {
    border: none;
    box-shadow: none;
    color: var(--amplify-colors-neutral-10);
  }
  
  &:active {
    color: var(--amplify-colors-neutral-60);
    border: none;
    background-color: transparent;
  }
  
  &:disabled {
    color: var(--amplify-colors-neutral-80);
  }
`;

export function LoginButton() {
    const navigate = useNavigate();

    return (
        <AuthButton marginInlineStart="auto" onClick={() => navigate('/login')}>
            <Icon as={MdLogin} marginInlineEnd="5px"/>
            Login
        </AuthButton>
    );
}

export function LogoutButton() {
    const {signOut} = useAuthenticator((context) => [
        context.signOut,
    ]);
    const navigate = useNavigate();

    function logOut() {
        signOut();
        navigate('/');
    }

    return (
        <AuthButton marginInlineStart="auto" onClick={() => logOut()}>
            <Icon as={MdLogout} marginInlineEnd="5px"/>
            Logout
        </AuthButton>
    );
}