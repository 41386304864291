import React from 'react';
import './App.css';
import { Authenticator } from '@aws-amplify/ui-react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Layout} from "./features/components/Layout";
import {HomePage} from "./features/pages/HomePage";
import {LoginPage} from "./features/pages/LoginPage";
import {RequireAuth} from "./features/components/RequireAuth";
import {AdminPage} from "./features/pages/AdminPage";

function GepsilonRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<HomePage />} />
                    <Route
                        path="/admin"
                        element={
                            <RequireAuth>
                                <AdminPage/>
                            </RequireAuth>
                        }
                    />
                    <Route path="/login" element={<LoginPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

function App() {
    return (
        <Authenticator.Provider>
            <GepsilonRoutes />
        </Authenticator.Provider>
    );
}

export default App;