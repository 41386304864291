import {Theme, defaultTheme} from "@aws-amplify/ui-react";

export const GepsilonTheme: Theme = {
    ...defaultTheme,
    name: 'gepsilon-theme',
    tokens: {
        fonts: {
            default: {
                variable: {value: 'Lato,sans-serif'},
                static: {value: 'Lato,sans-serif'},
            },
        },
        components: {
            button: {
                primary: {
                    backgroundColor: '{colors-neutral-90}'
                }
            }
        }
    }
};