import {
    Flex,
    Heading, Loader,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useAuthenticator,
    View
} from '@aws-amplify/ui-react';
import ComingSoon from "../components/ComingSoon";
import {useEffect, useState} from "react";

interface Tool {
    name: string,
    description: string
}

export function HomePage() {
    const [tools, setTools] = useState(new Array<Tool>());
    const [isLoading, setIsLoading] = useState(true);
    const {user} = useAuthenticator();
    const token = user.getSignInUserSession()?.getIdToken().getJwtToken();

    useEffect(() => {
        fetch('https://api.gepsilon.com/tools', {
            method: 'GET',
            headers: {
                Authorization: token || ''
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }

            throw response;
        }).then(tools => {
            setTools(tools);
            setIsLoading(false);
        });
    }, []);

    return (
        <View>
            <Flex direction="column" marginInline="auto" alignItems="center" padding="5rem">
                Hello {user.attributes?.email}
                {isLoading ? <Loader></Loader> :
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell as="th">Tool</TableCell>
                                <TableCell as="th">Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tools.map(tool =>
                                <TableRow
                                    key={tool.name}><TableCell>{tool.name}</TableCell><TableCell>{tool.description}</TableCell></TableRow>)}
                        </TableBody>
                    </Table>
                }
            </Flex>
        </View>
    );
}