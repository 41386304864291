import React from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {useAuthenticator, Button, Heading, View, Grid, ScrollView, Flex, useTheme} from '@aws-amplify/ui-react';
import {Header} from "./Header";
import {Footer} from "./Footer";
import {HomePage} from "../pages/HomePage";
import ComingSoon from "./ComingSoon";

function Content({route, pathname}) {
    if (route === 'authenticated' || pathname === '/login') {
        return <ScrollView flex="1 1 0">
            <Outlet/>
        </ScrollView>
    } else {
        return <ComingSoon/>
    }
}

export function Layout() {
    const {route} = useAuthenticator((context) => [
        context.route
    ]);
    const {pathname} = useLocation();

    const {tokens} = useTheme();

    return (
        <Flex backgroundColor={tokens.colors.background.primary}
              direction="column"
              height="100vh"
              width="100vw"
              gap="0">
            <Header/>
            <Flex gap="0" flex={1} direction="column">
                <Content route={route} pathname={pathname}/>
                <Footer/>
            </Flex>
        </Flex>
    );
}