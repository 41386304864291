import React from 'react';
import {Card, Flex, Text, useTheme} from "@aws-amplify/ui-react";
import styled from "styled-components";

const FooterContainer = styled(Card)`
  font-weight: lighter;
  font-size: 1rem;
  width: 100%;
  background-color: var(--amplify-colors-neutral-20);
  padding: 0.4rem;
  text-align: center;
`

const FooterSignature = styled(Text)`
  color: var(--amplify-colors-neutral-80);
`;

export function Footer() {
    const {tokens} = useTheme();

    return (
        <FooterContainer>
            <FooterSignature as="span">www.gepsilon.com</FooterSignature>
            {/*<Flex alignItems="center" marginInline="auto" direction="column">*/}
            {/*</Flex>*/}
        </FooterContainer>
    )
}