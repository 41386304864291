import React from "react";
import {
    BaseStyleProps,
    Button,
    Card,
    Flex,
    Heading, Icon,
    TabItem,
    Tabs,
    useAuthenticator,
    useTheme,
    View
} from '@aws-amplify/ui-react';
import {useLocation, useNavigate} from "react-router-dom";
import {BaseViewProps} from "@aws-amplify/ui-react/dist/types/primitives/types/view";
import {ForwardRefPrimitive} from "@aws-amplify/ui-react/dist/types/primitives/types";
import {ColorKeys} from "@aws-amplify/ui-react/dist/types/primitives/types/theme";
import {Property} from "csstype";
import {ResponsiveStyle, StyleToken} from "@aws-amplify/ui-react/dist/types/primitives/types/style";
import {NavbarButton} from "./NavbarButton";
import {MdLogin, MdLogout} from "react-icons/md";
import {GepsilonLogo} from "./GepsilonLogo";
import {LogoutButton, LoginButton} from "./AuthButtons";
import styled from "styled-components";


const GepsilonNavBar = styled(View)`
  padding-inline: .3rem;
  background-color: var(--amplify-colors-neutral-90);
  box-shadow: var(--amplify-shadows-large);
`;

export function Header() {
    const {tokens} = useTheme();
    const {route} = useAuthenticator((context) => [
        context.route
    ]);

    return (
        <GepsilonNavBar as='nav'>
            <Flex marginInline="auto" alignItems="center">
                <GepsilonLogo/>
                {/*<NavbarButton/>*/}
                {/*<Button variation="primary" onClick={() => navigate('/')}>Home</Button>*/}
                {/*<Button onClick={() => navigate('/admin')}>*/}
                {/*    Admin*/}
                {/*</Button>*/}
                {/*<Button onClick={() => navigate('/protected2')}>*/}
                {/*    Second Protected Route*/}
                {/*</Button>*/}
                {route !== 'authenticated' ? <LoginButton/> : <LogoutButton/>}
            </Flex>
        </GepsilonNavBar>
    );
}