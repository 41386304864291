import {useEffect} from "react";

import {Authenticator, Flex, useAuthenticator, View} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import {useNavigate, useLocation} from 'react-router';
import styled from "styled-components";


const GepsilonAuthenticator = styled(Authenticator)`
  margin: auto;
  padding-block-start: 2rem;
  
  [data-amplify-router] {
    border: none;
    box-shadow: none;
  }
`;

export function LoginPage() {
    const {route} = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();
    let from = location.state?.from?.pathname || '/';
    useEffect(() => {
        if (route === 'authenticated') {
            navigate(from, {replace: true});
        }
    }, [route, navigate, from]);
    return (
        <GepsilonAuthenticator loginMechanisms={['email']} hideSignUp={true}></GepsilonAuthenticator>
    );
}